import "./App.css";
import "./index.css";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/homepage/HomePage";
import ComingSoon from "./pages/comingsoon/ComingSoon";
import ServicePage from "./pages/servicespage/ServicesPage";
import BlogPage from "./pages/blogspage/BlogsPage";

function App() {

  return (
    <div className="App w-full overflow-hidden kite-cursor font-opensans bg-gray-200">
      <Routes>
        <Route index path="/" element={<HomePage />}></Route>
        {/* <Route path="/comingsoon" element={<ComingSoon />}></Route> */}
        <Route path="/service/:serviceName" element={<ServicePage />}></Route>
        <Route path="/blog/:blogid" element={<BlogPage />}></Route>
      </Routes>
    </div>
  );
}

export default App;
