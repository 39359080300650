import React, { useState } from 'react'
import { MdNotifications } from 'react-icons/md'
import { GoDotFill } from 'react-icons/go'
import StudioBooking from './StudioBooking'
import SuccessMessage from './SuccessMessage'
const Notifications = () => {

    const [isChecked, setIsChecked] = useState(false);
    const [isOpened, setIsOpened] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const handleButtonClick = () => {
        setIsChecked(true);
        setIsOpened(!isOpened);
    }

    const close = () => {
        setIsOpened(false);
    };

    const success = () => {
        setIsSuccess(true);
        setTimeout(() => {
            setIsSuccess(false);
        }, 5000);
    };

    // useEffect(() => {
    //     let timer;
    //     if (isOpened) {
    //         timer = setTimeout(() => {
    //             setIsOpened(false);
    //         }, 6000);
    //     }
    //     return () => {
    //         clearTimeout(timer);
    //     }
    // }, [isOpened]);

    return (
        <div className='bg-transparent flex z-40 fixed bottom-0 right-0 p-10 w-fit h-fit'>
            {isOpened && <StudioBooking
                success={success}
                close={close}
                consultation={true}
            />}
            {isSuccess && <SuccessMessage />}
            <div className='items-center justify-center'>
                {!isChecked && <GoDotFill className='text-red text-lg relative left-0 top-4' />}
                <button onClick={handleButtonClick} className='text-neutral-800 text-4xl p-2 rounded-full abosulte bg-white'>
                    <MdNotifications />
                </button>
            </div>
        </div>
    )
}

export default Notifications
