import React  from 'react'
import MainLayout from '../../components/MainLayout'
import Story from './containers/Story'
import XekaiStudio from './containers/XekaiStudio'
import Hero from './containers/Hero'
import Goals from './containers/Goals'
import ServicesPool from './containers/ServicesPool'
import Blogs from './containers/Blogs'
import ByXe from './containers/ByXe'
import { Helmet } from 'react-helmet-async'

const HomePage = () => {

  return (
    <div>
      <Helmet>
        <title>Home Page</title>
        <meta name='description' content='Visit Xekai website and discover our services' />
        <link rel='canonical' href='/' />
        <meta name='robots' content='noindex' />
      </Helmet>
      <MainLayout >
        <Hero />
        <Story />
        <Goals />
        <XekaiStudio />
        <ServicesPool />
        <ByXe />
        <Blogs />
      </MainLayout>
    </div>
  )
}

export default HomePage
