import React, { useState } from "react";
import { TbMailFilled } from "react-icons/tb";
import {
  FaFacebookSquare,
  FaInstagram,
  FaTiktok,
} from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";
import SuccessMessage from "./SuccessMessage";
import SubmissionError from "./SubmissionError";
import "../pages/homepage/containers/StayTuned.css";

export const Footer = () => {
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [company, setCompany] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const failed = () => {
    setIsFailed(true);
    setTimeout(() => {
      setIsFailed(false);
    }, 5000);
  };
  const success = () => {
    setIsSuccess(true);
    setTimeout(() => {
      setIsSuccess(false);
    }, 5000);
  };
  const submitMessage = () => {
    if (name === "" || email === "" || company === "" || number === "") {
      failed();
      return;
    }
    const mailBody = `
    <p>Name: ${name}</p>
    <p>Email: ${email}</p>
    <p>Number: ${number}</p>
    <p>Company: ${company}</p>
    <p>Message: ${message}</p>
  `;
    const config = {
      SecureToken: "019dfdba-601a-4249-b999-a4f2582f079e",
      To: "info@xekai.co",
      From: "info@xekai.co",
      Subject: "Message from contact form",
      Body: mailBody,
    };

    if (window.Email) {
      window.Email.send(config);
    }
    setMessage("");
    success();
  };

  return (
    <>
      <svg
        className="w-full h-auto max-h-40 translate-y-[1px]"
        preserveAspectRatio="none"
        viewBox="0 0 1152 263"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Wave"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1656 262.5H-504V0C-144 80 216 120 576 120C936 120 1296 80 1656 0V262.5Z"
          fill="#27272a"
        />
      </svg>
      <section className="flex items-center justify-center w-full bg-zinc-800">
        {isSuccess && <SuccessMessage />}
        {isFailed && <SubmissionError />}
        <footer className="container w-full pb-6">
          <section className="relative w-full">
            <div className="container flex flex-col md:grid w-full grid-cols-6 py-10">
              <div className="flex flex-col items-center justify-center col-span-2 gap-y-6 border-b-2 mx-12 md:mx-0 pb-12 md:pb-0 md:border-b-0 md:border-r-2 px-4 border-r-white">
                <div className="flex flex-col gap-y-6">
                  <div>
                    <span className="text-2xl text-neutral-500">
                      Email Address
                    </span>
                    <div className="container pt-2 flex gap-4 items-center">
                      <TbMailFilled className="text-2xl text-yellow" />
                      <span className="text-xl text-gray-400 md:text-gray-300">
                        info@xekai.co
                      </span>
                    </div>
                  </div>
                  {/* <div>
                    <span className="text-2xl text-neutral-500">
                      Phone Number
                    </span>
                    <div className="container pt-2 flex gap-4 items-center">
                      <FaPhone className="text-2xl text-yellow" />
                      <span className="text-xl text-gray-400 md:text-gray-300">
                        +201017339044
                      </span>
                    </div>
                  </div> */}
                  <div className="max-w-xs">
                    <span className="text-2xl text-neutral-500">Address</span>
                    <div className="container pt-2 flex gap-4 items-center">
                      <MdLocationPin className="text-2xl text-yellow" />
                      <span className="text-xl break-words text-gray-400 md:text-gray-300">
                        8B Dr Atef Sedky, Sheikh Zayed, Egypt
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-1 border-t-2 border-white">
                  <div className="flex w-full pt-6 text-gray-400 md:text-gray-300">
                    <span className="text-md text-center">Stay updated with our latest projects, industry insights and behind-the-scenes content by following us on social media</span>
                  </div>
                  <div className="flex justify-between items-center px-8 md:px-24">
                    <div className="">
                      <a
                        href="https://www.facebook.com/profile.php?id=61556666941804&mibextid=LQQJ4d"
                        className="container pt-3 flex gap-4 items-start"
                      >
                        <FaFacebookSquare className="transition-all duration-500 text-yellow hover:text-neutral-600 text-2xl" />
                      </a>
                    </div>
                    <div className="">
                      <a
                        href="https://www.instagram.com/xekaiexperiential?igsh=MXNxZXMwb3B6dmt4dA=="
                        className="container pt-3 flex gap-4 items-center cursor-pointer"
                      >
                        <FaInstagram className="transition-all duration-500 text-yellow hover:text-neutral-600 text-2xl" />
                      </a>
                    </div>
                    <div className="">
                      <a
                        href="https://www.tiktok.com/@xekai.experiential?_t=8lAv1Rq8PLh&_r=1"
                        className="container pt-3 flex gap-4 items-center"
                      >
                        <FaTiktok className="transition-all duration-500 text-yellow hover:text-neutral-600 text-2xl" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div id="contactus" className="col-span-4 pt-12 md:pt-0 md:px-4">
                <h3 className="text-5xl text-center text-neutral-500">
                  Contact Us
                </h3>
                <div className="px-4 md:px-10 pt-8">
                  <p className="text-gray-300 text-center text-xl">
                    At Xekai, we believe in the power of unforgettable experiences. Whether you're launching a new product, hosting an event,
                    or looking to create a deeper connection with your audience, our team of creative professionals is here to help.
                  </p>
                  <div className="flex flex-col items-center justify-center mx-6 md:mx-16 gap-y-4 pt-6">
                    <div className="grid grid-cols-2 items-center justify-between w-full gap-2">
                      <div className="flex flex-col">
                        <span className="text-xl text-neutral-500 p-2">Name</span>
                        <input
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          type="text"
                          placeholder="Name"
                          className="border-2 focus:border-cyan focus:outline-none rounded-lg w-full px-4 py-3"></input>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xl text-neutral-500 p-2">Email</span>
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="text"
                          placeholder="Email"
                          className="border-2 focus:border-cyan focus:outline-none rounded-lg w-full px-4 py-3"></input>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xl text-neutral-500 p-2">Number</span>
                        <input
                          value={number}
                          onChange={(e) => setNumber(e.target.value)}
                          type="text"
                          placeholder="Number"
                          className="border-2 focus:border-cyan focus:outline-none rounded-lg w-full px-4 py-3"></input>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xl text-neutral-500 p-2">Company</span>
                        <input
                          value={company}
                          onChange={(e) => setCompany(e.target.value)}
                          type="text"
                          placeholder="Company"
                          className="border-2 focus:border-cyan focus:outline-none rounded-lg w-full px-4 py-3"></input>
                      </div>
                    </div>
                    <div className="flex flex-col w-full">
                      <span className="text-xl text-neutral-500 p-2">Message</span>
                      <input
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        type="text"
                        placeholder="Write a message..."
                        className="border-2 focus:border-cyan focus:outline-none rounded-lg w-full p-4"
                      ></input>
                    </div>
                    <button
                      onClick={submitMessage}
                      type="submit"
                      className="transition-all duration-500 hover:animate-pulse hover:scale-105 rounded-full mt-2 py-2 px-12 md:px-8 text-xl bg-yellow text-neutral-800"
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="flex flex-col items-center space-y-4 md:col-span-12 lg:col-span-10 text-white">
            <p className="font-bold italic text-gray-300 ">
              Copyright © 2024. Xekai
            </p>
          </div>
        </footer>
      </section>
    </>
  );
};

export default Footer;
