import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import '../App.css'
const ServiceCard = ({
  serviceName = "id",
  Title,
  Logo,
  className,
  logoClassName,
  Logoheight,
  Logowidth,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isHover, setIsHover] = useState(true);

  const defaultOptions = {
    loop: true,
    autoplay: false,
    animationData: Logo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Link to={`/service/${serviceName}`} className="no-underline" onMouseEnter={() => setIsHover(false)} onMouseLeave={() => setIsHover(true)}>
      <div
       on
        className={`relative overflow-hidden bg-gray-200 rounded-3xl p-4 md:p-8 mx-auto w-full transition duration-700 ease-in-out transform group hover:scale-105 kite-cursor ${className}`}
      >
        <div className={`flex flex-col items-center`}>
          <div
            className={`${logoClassName} md:mb-2 transition-all duration-500 opacity-100`}
          >
            <Lottie
              isClickToPauseDisabled={false}
              options={defaultOptions}
              height={Logoheight}
              width={Logowidth}
              isStopped={isHover}
              isPaused={isHover}
            />
          </div>

          <h3
            className={` text-center justify-center flex pt-6 md:pt-3 text-neutral-800 mb-0 transition-all duration-500 opacity-100`}
          >
            {Title}
          </h3>
        </div>
      </div>
    </Link>
  );
};

export default ServiceCard;
