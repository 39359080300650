import icons from "../constants/animatedIcons.js";

export const servicesData = [
  {
    serviceName: "WebDevelopment",
    Title: "Web Development",
    Logo: icons.WebDevelopment,
    text: "Creating, building and maintaining websites, UI/UX designs.",
  },
  {
    serviceName: "BusinessDevelopment",
    Title: "Business Development",
    Logo: icons.BusinessDevelopment,
    text: "Improving the performance of your business, developing business growth and to attract potential clients.",
  },
  {
    serviceName: "MediaBuying",
    Title: "Media Buying",
    Logo: icons.MediaBuying,
    text: "We identify your target audience, set up your campaign, track and optimize the results.",
  },
  {
    serviceName: "SEO",
    Title: "SEO",
    Logo: icons.SEO,
    text: "SEO (Search engine optimization) is improving the website visibility and ranking on search engine pages. With our professional team who are experts in SEO we’ll make sure to give you successful SEO strategies.",
  },
  {
    serviceName: "Printing",
    Title: "Printing",
    Logo: icons.Printing,
    text: "Anything printed like business cards, notebooks, mugs, and many more. We got you covered!",
  },
  {
    serviceName: "Branding",
    Title: "Branding",
    Logo: icons.Branding,
    text: "Including brand strategy, designing your logo, brand identity (logo, brand name, tagline, and visual identity).",
  },
  {
    serviceName: "PR",
    Title: "PR",
    Logo: icons.PR,
    text: "Want to be seen and known more? This is your place! We will create creative and powerful campaigns for your brand with the right influencers needed to execute a successful campaign.",
  },
  {
    serviceName: "SocialMediaManagement",
    Title: "Social Media Management",
    Logo: icons.SocialMediaManagement,
    text: "Starting from setting up your accounts to creating your social media strategy & plan followed by content creation and postings, and finally to analyze key performance metrics.",
  },
  {
    serviceName: "PhotographyVideography",
    Title: "Photography & Videography",
    Logo: icons.Videography,
    text: "To make your content shine more than others, we will help you with all the content creation tools with the help of our professional team and equipment.",
  },
  {
    serviceName: "GraphicDesignVideoEditing",
    Title: "Graphic design & video editing",
    Logo: icons.GraphicDesign,
    text: "Whether you are looking to design social media posts, or you want to have a professional video editor, we got you covered!",
  },
];
