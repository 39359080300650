import React from 'react'

const SubmissionError = () => {
  return (
    <div className='fixed top-0 mt-5 z-50 bg-white rounded-full border-2 border-red text-black py-4 px-12 font-oswald flex items-center justify-center text-center'>
      <span>Please check the data you entered</span>
    </div>
  )
}

export default SubmissionError
