import React, { useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import blogs from "../../Data/blogs.js";
import { Header } from "../../components/Header.jsx";
import { Footer } from "../../components/Footer.jsx";
import BlogCard from "./BlogCard";

const BlogPage = () => {
  const { blogid } = useParams();
  const topRef = useRef(null);

  const selectedBlog = blogs.find((blog) => blog.id === parseInt(blogid));
  const unselectedBlogs = blogs.filter((blog) => blog.id !== parseInt(blogid));

  useEffect(() => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
  }, [blogid]);

  if (!selectedBlog) {
    return (
      <div className="bg-white flex flex-col items-center justify-center h-screen">
        <h1>Blog not found!</h1>
      </div>
    );
  }

  return (
    <div className="bg-white flex flex-col items-center">
      <div ref={topRef} />
      <div className="bg-black w-full mb-8">
        <Header isAbsolute={false} />
      </div>

      <div
        className="container bg-gray-300 text-center flex flex-col my-8 items-center justify-center rounded-3xl p-10 shadow-lg font-roboto"
        style={{ width: "calc(88%)" }}
      >
        <h1 className="text-3xl font-bold mb-4">{selectedBlog.title}</h1>
        <h2 className="text-xl font-semibold text-gray-700 mb-6">
          {selectedBlog.subtitle}
        </h2>
        <p className="text-gray-800 text-lg mb-4 pt-4">
          {selectedBlog.description}
        </p>
        <div className="flex justify-between items-center pt-8">
          <div className="text-gray-700 text-xl">{selectedBlog.date}</div>
        </div>
      </div>

      <div
        className="container flex flex-col items-center justify-center my-8 "
        style={{ width: "calc(88%)" }}
      >
        <h2 className="text-2xl font-bold mb-4">Other Blogs</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {unselectedBlogs.map((blog) => (
            <BlogCard key={blog.id} blog={blog} />
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default BlogPage;
