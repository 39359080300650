import React from "react";
import blogs from "../../../Data/blogs";
import { Link } from "react-router-dom";
const Blogs = () => {
  return (
    <div
      id="blogs"
      className="font-oswald bg-gray-200 flex flex-col pt-12 md:pt-32 md:relative"
    >
      <div className="m-10 relative font-oswald">
        <div className="text-neutral-800 text-[40px] underline underline-offset-8 text-center pb-16">
          Blogs
        </div>
        <div className="grid md:grid-cols-2 gap-10">
          {blogs.map((blog) => (
            <div
              key={blog.id}
              className="flex justify-center items-center text-center hover:scale-105 shadow-xl hover:shadow-lg rounded-full transition-all duration-500 cursor-pointer py-8"
            >
              <Link to={`/blog/${blog.id}`} className="no-underline">
                {" "}
                <div className="flex flex-col gap-y-2">
                  <div>
                    <span className="text-red text-md md:text-3xl">{blog.date}: </span>
                    <span className="text-neutral-800 text-lg md:text-3xl">
                      {blog.title}
                    </span>
                  </div>
                  <span className="text-neutral-700 text-md md:text-xl">
                    {blog.subtitle}
                  </span>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blogs;
