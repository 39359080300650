import React from "react";
import { Link } from "react-router-dom";

const BlogCard = ({ blog }) => {
  return (
    <Link
      to={`/blog/${blog.id}`}
      className="no-underline transition duration-300 block"
    >
      <div className="relative overflow-hidden rounded-3xl p-8 mx-auto w-full transition duration-700 ease-in-out transform group cursor-pointer shadow border border-gray-300 hover:bg-gray-300 hover:shadow-lg">
        <div className="flex flex-col items-center">
          <h3 className="text-3xl text-center justify-center flex pt-6 md:pt-0 text-neutral-800 mb-0 transition-all duration-500 opacity-100">
            {blog.title}
          </h3>
          <h4 className="text-xl text-center justify-center flex pt-6 md:pt-0 text-neutral-700 mb-4 transition-all duration-500 opacity-100">
            {blog.subtitle}
          </h4>
          <p className="text-gray-700 text-lg mb-4 pt-4">
            {blog.description.substring(0, 150)}...
          </p>
          <div className="flex justify-between items-center pt-8">
            <div className="text-gray-700 text-xl">{blog.date}</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;
