import React from 'react'

const Goals = () => {
  return (
    <div id='goals' className='relative bg-neutral-800'>
      <div className='flex flex-col md:grid grid-cols-2 items-center font-oswald bg-neutral-800 pt-32 text-gray-200'>
        <div className='flex flex-col items-center  h-full text-center p-6 lg:px-24 w-full font-oswald py-8'>
          <div className='pb-6 font-anton text-4xl'>Mission</div>
          <span className='text-lg md:text-2xl lg:text-4xl'>
            We're on a mission to inspire people to believe in themselves and #invest in all our talents and power to reach a financial mindset
          </span>
        </div>
        <div className='flex flex-col items-center  h-full text-center p-6 lg:px-24 w-full font-oswald py-8'>
          <div className='pb-6 font-anton text-4xl'>Vision</div>
          <span className='text-lg md:text-2xl lg:text-4xl'>
            We aim to invest in the quality of life by making our clients go beyond their own expectations
          </span>
        </div>

      </div>
      <div className='absolute bottom-0 w-full h-20 z-30 bg-neutral-800 origin-right transform -skew-y-3'></div>
      <div className='relative w-full h-20 z-20 bg-white origin-right transform -skew-y-6'></div>
    </div>
  )
}

export default Goals
