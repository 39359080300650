import React from "react";
import ServiceCard from "../../../components/ServicesCard";
import icons from "../../../constants/animatedIcons";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';

const ServicesPool = () => {
  return (
    <div
      id="services"
      className="bg-neutral-800 font-oswald flex flex-col lg:pt-28 lg:relative"
    >
      {window.innerWidth < 1023 ? (
        <div className="relative">
          <Carousel
            className="px-4 py-16"
            showArrows={true}
            showThumbs={false}
            infiniteLoop={true}
            autoPlay={false}
            interval={2000}
            transitionTime={500}
            showStatus={false}
          >
            <div className="flex flex-col p-6">
              <div className=" grid grid-cols-2">
                <div className="bg-neutral-800 p-2 rounded-3xl">
                  <ServiceCard
                    className="hover:bg-gradient-to-br hover:from-gray-200 h-[200px] hover:to-neutral-700"
                    logoClassName={"h-[100px]"}
                    Title="Web Development"
                    text="Creating, building and maintaining websites, UI/UX designs."
                    Logo={icons.WebDevelopment}
                    serviceName="WebDevelopment"
                  />
                </div>
                <div className="bg-neutral-800 p-2 rounded-3xl">
                  <ServiceCard
                    className="hover:bg-gradient-to-br hover:from-gray-200 h-[200px] hover:to-neutral-700"
                    logoClassName={"h-[100px]"}
                    Title="Business Development"
                    text="Improving the performance of your business, developing business growth and to attract potential clients."
                    Logo={icons.BusinessDevelopment}
                    serviceName="BusinessDevelopment"
                  />
                </div>
              </div>
              <div className="bg-neutral-800 p-2 rounded-3xl">
                <ServiceCard
                  className="hover:bg-gradient-to-br hover:from-gray-200 h-[200px] hover:to-neutral-700 gap-[36px]"
                  logoClassName={"h-[110px]"}
                  Title="Media Buying"
                  text="We identify your target audience,set up your campaign,track and optimize the results."
                  Logo={icons.MediaBuying}
                  serviceName="MediaBuying"
                />
              </div>
            </div>
            <div className="flex flex-col p-6">
              <div className="grid grid-cols-2">
                <div className="bg-neutral-800 p-2 rounded-3xl ">
                  <ServiceCard
                    className="hover:bg-gradient-to-br hover:from-gray-200 h-[200px] hover:to-neutral-700"
                    logoClassName={"h-[100px]"}
                    Title="SEO"
                    text="SEO ( Search engine optimization ) is improving the website visibility and ranking on search engine pages. With our professional team who are experts in SEO we’ll make sure to give you successful SEO strategies. "
                    Logo={icons.SEO}
                    serviceName="SEO"
                  />
                </div>
                <div className="bg-neutral-800 p-2 rounded-3xl ">
                  <ServiceCard
                    className="hover:bg-gradient-to-br hover:from-gray-200 h-[200px] hover:to-neutral-700"
                    logoClassName={"h-[100px]"}
                    Title="Printing"
                    text="Anything printed like business cards, notebooks, mugs, and many more. We got you covered !"
                    Logo={icons.Printing}
                    serviceName="Printing"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2">
                <div className="bg-neutral-800 p-2 rounded-3xl ">
                  <ServiceCard
                    className="hover:bg-gradient-to-br hover:from-gray-200 h-[200px] hover:to-neutral-700"
                    logoClassName={"h-[100px]"}
                    Title="Branding"
                    text="Including brand strategy, designing your logo  brand identity ( logo, brand name, tagline and visual identity )."
                    Logo={icons.Branding}
                    serviceName="Branding"
                  />
                </div>
                <div className=" bg-neutral-800 p-2 rounded-3xl">
                  <ServiceCard
                    className="hover:bg-gradient-to-br hover:from-gray-200 h-[200px] hover:to-neutral-700"
                    logoClassName={"h-[100px]"}
                    Title="PR"
                    text="Want to be seen and known more ? this is your place! We will create creative and powerful campaigns for your brand  with the right influencers needed to execute a successful campaign."
                    Logo={icons.PR}
                    serviceName="PR"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col p-6">
              <div className="w-full bg-neutral-800 p-2 rounded-3xl">
                <ServiceCard
                  className="hover:bg-gradient-to-br hover:from-gray-200 h-[200px] hover:to-neutral-700"
                  logoClassName={"h-[120px]"}
                  Title="Social Media Management"
                  text="Starting from setting up your accounts to creating your social media strategy & plan followed by content creation and postings , and finally to analyze key performance metrics."
                  Logo={icons.SocialMediaManagement}
                  serviceName="SocialMediaManagement"
                />
              </div>
              <div className="grid grid-cols-2">
                <div className="bg-neutral-800 p-2 rounded-3xl w-full">
                  <ServiceCard
                    className="hover:bg-gradient-to-br hover:from-gray-200 h-[200px] hover:to-neutral-700"
                    logoClassName={"h-[90px]"}
                    Title="Photography & Videography"
                    text="To make your content shine more than others, we will help you with all the content creation tools with the help of our professional team and equipment."
                    Logo={icons.Videography}
                    serviceName="PhotographyVideography"
                  />
                </div>
                <div className="bg-neutral-800 p-2 rounded-3xl w-full">
                  <ServiceCard
                    className="hover:bg-gradient-to-br hover:from-gray-200 h-[200px] hover:to-neutral-700"
                    logoClassName={"h-[90px]"}
                    Title="Graphic design & video editing"
                    text="Whether you are looking to design social media posts, or you want to have a professional video editor, we got you covered!"
                    Logo={icons.GraphicDesign}
                    serviceName="GraphicDesignVideoEditing"
                  />
                </div>
              </div>
            </div>
          </Carousel>
          <div className="absolute bottom-0 w-full h-20 z-30 bg-neutral-800 origin-right transform -skew-y-3"></div>
          <div className="relative w-full h-20 z-20 bg-white origin-right transform -skew-y-6"></div>
        </div>
      ) : (
        <div>
          <div className="text-gray-300 flex justify-center pt-6 text-[42px]">
            Services
          </div>
          <div className="grid grid-cols-12 gap-4 p-10 pb-20 text-neutral-800 h-full text-xl">
            <div className="col-span-3 w-full space-y-4">
              <div className="bg-gray-200 shadow-md rounded-3xl">
                <ServiceCard
                  className="hover:bg-gradient-to-br hover:from-gray-200 h-[316px] hover:to-neutral-700"
                  logoClassName={"h-[190px]"}
                  Title="Web Development"
                  text="Creating, building and maintaining websites, UI/UX designs."
                  Logo={icons.WebDevelopment}
                  serviceName="WebDevelopment"
                  Logoheight={200}
                  Logowidth={200}
                />
              </div>
              <div className="bg-gray-200 shadow-md rounded-3xl">
                <ServiceCard
                  className="hover:bg-gradient-to-br hover:from-gray-200 h-[250px] hover:to-neutral-700"
                  logoClassName={"h-[125px]"}
                  Title="Business Development"
                  text="Improving the performance of your business, developing business growth and to attract potential clients."
                  Logo={icons.BusinessDevelopment}
                  serviceName="BusinessDevelopment"
                  Logoheight={140}
                  Logowidth={140}
                />
              </div>
              <div className="bg-gray-200 shadow-md rounded-3xl">
                <ServiceCard
                  className="hover:bg-gradient-to-br hover:from-gray-200 h-[250px] hover:to-neutral-700"
                  logoClassName={"h-[140px]"}
                  Title="Media Buying"
                  text="We identify your target audience,set up your campaign,track and optimize the results."
                  Logo={icons.MediaBuying}
                  serviceName="MediaBuying"
                  Logoheight={140}
                  Logowidth={170}
                />
              </div>
            </div>
            <div className="col-span-6 w-full space-y-4 ">
              <div className="bg-gray-200 shadow-md rounded-3xl w-full">
                <ServiceCard
                  className="hover:bg-gradient-to-br hover:from-gray-200 h-[200px] hover:to-neutral-700"
                  logoClassName={"h-[110px]"}
                  Title="SEO"
                  text="SEO ( Search engine optimization ) is improving the website visibility and ranking on search engine pages. With our professional team who are experts in SEO we’ll make sure to give you successful SEO strategies. "
                  Logo={icons.SEO}
                  serviceName="SEO"
                  Logoheight={120}
                  Logowidth={150}
                />
              </div>
              <div className="flex gap-4">
                <div className="bg-gray-200 shadow-md rounded-3xl h-full w-full">
                  <ServiceCard
                    className="hover:bg-gradient-to-br hover:from-gray-200 h-[200px] hover:to-neutral-700"
                    logoClassName={"h-[110px]"}
                    Title="Printing"
                    text="Anything printed like business cards, notebooks, mugs, and many more. We got you covered !"
                    Logo={icons.Printing}
                    serviceName="Printing"
                    Logoheight={140}
                    Logowidth={140}
                  />
                </div>
                <div className="bg-gray-200 shadow-md rounded-3xl h-full w-full">
                  <ServiceCard
                    className="hover:bg-gradient-to-br hover:from-gray-200 h-[200px] hover:to-neutral-700"
                    logoClassName={"h-[110px]"}
                    Title="Branding"
                    text="Including brand strategy, designing your logo  brand identity ( logo, brand name, tagline and visual identity )."
                    Logo={icons.Branding}
                    serviceName="Branding"
                    Logoheight={130}
                    Logowidth={130}
                  />
                </div>
              </div>
              <div className="w-full bg-gray-200 shadow-md rounded-3xl">
                <ServiceCard
                  className="hover:bg-gradient-to-br hover:from-gray-200 h-[200px] hover:to-neutral-700"
                  logoClassName={"h-[110px]"}
                  Title="PR"
                  text="Want to be seen and known more ? this is your place! We will create creative and powerful campaigns for your brand  with the right influencers needed to execute a successful campaign."
                  Logo={icons.PR}
                  serviceName="PR"
                  Logoheight={130}
                  Logowidth={170}
                />
              </div>
              <div className="w-full bg-gray-200 shadow-md rounded-3xl">
                <ServiceCard
                  className="hover:bg-gradient-to-br hover:from-gray-200 h-[200px] hover:to-neutral-700"
                  logoClassName={"h-[110px]"}
                  Title="Social Media Management"
                  text="Starting from setting up your accounts to creating your social media strategy & plan followed by content creation and postings , and finally to analyze key performance metrics."
                  Logo={icons.SocialMediaManagement}
                  serviceName="SocialMediaManagement"
                  Logoheight={140}
                  Logowidth={140}
                />
              </div>
            </div>
            <div className="col-span-3 w-full space-y-4">
              <div className="bg-gray-200 shadow-md rounded-3xl w-full">
                <ServiceCard
                  className="hover:bg-gradient-to-br hover:from-gray-200 h-[416px] hover:to-neutral-700"
                  logoClassName={"h-[240px]"}
                  Title="Photography & Videography"
                  text="To make your content shine more than others, we will help you with all the content creation tools with the help of our professional team and equipment."
                  Logo={icons.Videography}
                  serviceName="PhotographyVideography"
                  Logoheight={200}
                  Logowidth={200}
                />
              </div>
              <div className="bg-gray-200 shadow-md rounded-3xl w-full">
                <ServiceCard
                  className="hover:bg-gradient-to-br hover:from-gray-200 h-[416px] hover:to-neutral-700"
                  logoClassName={"h-[240px]"}
                  Title="Graphic design & video editing"
                  text="Whether you are looking to design social media posts, or you want to have a professional video editor, we got you covered!"
                  Logo={icons.GraphicDesign}
                  serviceName="GraphicDesignVideoEditing"
                  Logoheight={160}
                  Logowidth={210}
                />
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 w-full h-20 z-30 bg-neutral-800 origin-right transform -skew-y-3"></div>
          <div className="relative w-full h-20 z-20 bg-white origin-right transform -skew-y-6"></div>
        </div>
      )}
    </div>
  );
};

export default ServicesPool;
