import React, { useState } from "react";
import images from "../constants/Images";
import StudioBooking from "./StudioBooking";
import SuccessMessage from "./SuccessMessage";
import "../pages/homepage/containers/StayTuned.css";
import "../App.css";
import { Link, useNavigate } from "react-router-dom";
import Notifications from "./Notifications";
export const Header = ({ isAbsolute = true }) => {
  const navigate = useNavigate();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  const [isBooking, setIsBooking] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  var windowSize = window.innerWidth;

  const success = () => {
    setIsSuccess(true);
    setTimeout(() => {
      setIsSuccess(false);
    }, 5000);
  };
  const close = () => {
    setIsBooking(false);
  };

  const handleNavigateBackAndScroll = (sectionId) => {
    if (sectionId === "contactus") {
      document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" });
      return;
    }
    if (isAbsolute) {
      document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" });
    } else {
      navigate("/");
      setTimeout(() => {
        document
          .getElementById(sectionId)
          .scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };
  return (
    <div
      id="header"
      className={`flex ${isAbsolute && "absolute"
        } top-0 z-40 right-0 left-0 items-center justify-between h-fit bg-transparent`}
    >
      <Notifications />
      <div>
        <Link to="/">
          <div className="px-2 md:px-4 py-4 md:ml-6">
            <img
              src={images.Logo}
              alt="Logo"
              className="max-h-14 md:max-h-16 h-auto"
            />
          </div>
        </Link>
      </div>
      {windowSize < 1024 ?
        <div className="relative group text-2xl font-oswald">
          <button
            onClick={() => setIsBooking(true)}
            className="text-sm md:text-xl hover:scale-105 hover:animate-pulse border border-yellow bg-yellow py-2 px-2 rounded-full text-neutral-800 hover:bg-gray-200 hover:text-neutral-950 transition-all duration-300"
          >
            Book a free consultation
          </button>
          {isBooking && (
            <StudioBooking
              success={success}
              close={close}
              consultation={true}
            />
          )}
          {isSuccess && <SuccessMessage />}
        </div>
        : <></>}
      <div className=" text-white">
        <ul className={`flex items-center h-full text-center gap-12 ${(Math.round((window.devicePixelRatio || 1) * 100) > 100) ? 'md:gap-12' : 'md:gap-14'} mr-4 md:mr-16 text-2xl font-oswald`}>
          {windowSize > 1023 ?
            <li className="relative group">
              <button
                onClick={() => setIsBooking(true)}
                className="text-sm md:text-xl hover:scale-105 hover:animate-pulse border border-yellow bg-yellow py-2 px-4 rounded-full text-neutral-800 hover:bg-gray-200 hover:text-neutral-950 transition-all duration-300"
              >
                Book a free consultation
              </button>
              {isBooking && (
                <StudioBooking
                  success={success}
                  close={close}
                  consultation={true}
                />
              )}
              {isSuccess && <SuccessMessage />}
            </li> : <></>
          }
          <li className="relative group hidden md:block">
            <>
              <button
                onClick={() => handleNavigateBackAndScroll("story")}
                className="group-hover:scale-105 group-hover:animate-pulse group-hover:text-yellow text-gray-200 transition-all duration-300"
              >
                Our Story
              </button>
            </>
          </li>
          <li className="relative group hidden md:block">
            <>
              <button
                onClick={() => handleNavigateBackAndScroll("services")}
                className="group-hover:scale-105 group-hover:animate-pulse group-hover:text-yellow text-gray-200 transition-all duration-300"
              >
                Services
              </button>
            </>
          </li>
          <li className="relative group hidden md:block">
            <>
              <button
                onClick={() => handleNavigateBackAndScroll("contactus")}
                className="group-hover:scale-105 group-hover:animate-pulse group-hover:text-yellow text-gray-200 transition-all duration-300"
              >
                Contact Us
              </button>
            </>
          </li>
          <li className="relative group w-10 md:w-12 z-50">
            <button
              onClick={toggleMenu}
              className={`hover:scale-105 hover:animate-pulse flex flex-col items-center w-full justify-center gap-y-1 md:p-2 bg-transparent transition-all duration-500 ${isMenuOpened ? "-rotate-90" : ""
                }`}
            >
              <div className="h-1.5 w-full bg-gray-200 rounded-lg relative">
                <div
                  className={`absolute left-0 top-0 bg-yellow rounded-lg h-full w-0 transition-all duration-500 ${isMenuOpened ? "w-full" : ""
                    }`}
                ></div>
              </div>
              <div
                className={`h-1.5 w-full rounded-lg transition-all duration-500 relative ${isMenuOpened ? "bg-white" : "bg-gray-200"
                  }`}
              ></div>
              <div className="h-1.5 w-full bg-gray-200 rounded-lg relative">
                <div
                  className={`absolute right-0 top-0 bg-cyan rounded-lg h-full w-0 transition-all duration-500 ${isMenuOpened ? "w-full" : ""
                    }`}
                ></div>
              </div>
            </button>
          </li>
        </ul>
        <div
          className={`fixed z-40 top-0 left-0 w-full ${isMenuOpened ? "h-full" : "h-0"
            } transition-all duration-500 bg-neutral-800 bg-opacity-95`}
        >
          <div
            className={`flex flex-col gap-y-8 pt-20 md:pt-10 fixed left-0 w-full ${isMenuOpened ? "top-0" : "-top-full"
              } transition-all duration-500`}
          >
            <div className={`${isMenuOpened ? '' : 'invisible transition-all duration-500'} flex flex-col items-center gap-y-6 justify-center`}>
              <button
                onClick={() => {
                  setIsMenuOpened(false);
                  handleNavigateBackAndScroll("story");
                }}
                className="text-3xl md:text-2xl"
              >
                Our Story
              </button>
              <div className="bg-yellow w-6/12 h-1"></div>
            </div>
            <div className={`${isMenuOpened ? '' : 'invisible transition-all duration-500'} flex flex-col items-center gap-y-6 justify-center`}>
              <button
                onClick={() => {
                  setIsMenuOpened(false);
                  handleNavigateBackAndScroll("services");
                }}
                className="text-3xl md:text-2xl"
              >
                Services
              </button>
              <div className="bg-yellow w-6/12 h-1"></div>
            </div>
            <div className={`${isMenuOpened ? '' : 'invisible transition-all duration-500'} flex flex-col items-center gap-y-6 justify-center`}>
              <button
                onClick={() => {
                  setIsMenuOpened(false);
                  handleNavigateBackAndScroll("studio");
                }}
                className="text-3xl md:text-2xl"
              >
                Xekai Studio
              </button>
              <div className="bg-yellow w-6/12 h-1"></div>
            </div>
            <div className={`${isMenuOpened ? '' : 'invisible transition-all duration-500'} flex flex-col items-center gap-y-6 justify-center`}>
              <button
                onClick={() => {
                  setIsMenuOpened(false);
                  handleNavigateBackAndScroll("byxe");
                }}
                className="text-3xl md:text-2xl"
              >
                By Xe
              </button>
              <div className="bg-yellow w-6/12 h-1"></div>
            </div>
            <div className={`${isMenuOpened ? '' : 'invisible transition-all duration-500'} flex flex-col items-center gap-y-6 justify-center`}>
              <button
                onClick={() => {
                  setIsMenuOpened(false);
                  handleNavigateBackAndScroll("blogs");
                }}
                className="text-3xl md:text-2xl"
              >
                Blogs
              </button>
              <div className="bg-yellow w-6/12 h-1"></div>
            </div>
            <div className={`${isMenuOpened ? '' : 'invisible transition-all duration-500'} flex flex-col items-center gap-y-6 justify-center`}>
              <button
                onClick={() => {
                  setIsMenuOpened(false);
                  handleNavigateBackAndScroll("contactus");
                }}
                className="text-3xl md:text-2xl"
              >
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
