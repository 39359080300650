import React from 'react'
import images from '../../../constants/Images'

const Story = () => {

  return (
    <div id='story' className='kite-cursor relative flex flex-col items-center bg-gray-200 text-neutral-800 font-oswald'>
      <div className='relative hidden md:block w-full h-20 z-20 bg-white origin-right transform skew-y-6'></div>
      <div className='absolute hidden md:block w-full h-20 z-30 bg-gray-200 origin-right transform skew-y-3'></div>
      <div className='relative pt-6 md:pt-0 flex flex-col gap-y-12 md:grid grid-cols-2 text-center items-center gap-x-10 px-12 md:px-24'>
        <div className='flex flex-col w-full gap-2'>
          <h3 className=' text-neutral-800 text-[40px] underline underline-offset-8 text-center pb-8'>Our Story</h3>
          <span className='text-2xl md:text-3xl lg:text-[28px]'>
            We Are People Who Are Passionate About Our Work
          </span>
          <span className='text-lg md:text-2xl lg:text-[24px]'>
            In 2023, our co-founders who are experienced in the business development field since 2012 and who worked in several fields like auctions, deep cleaning, house cleaning and real estate; they saw the opportunity to start their new startup xekai.
            Xekai is an Emirate-Egyptian company which is part of a company called Jazi Holding in UAE that works in Egypt, Gulf, and Europe.
          </span>
        </div>
        <img src={images.World} className='block' alt='' />
      </div>
      <div className='absolute bottom-0 w-full h-20 z-30 bg-gray-200 origin-left transform skew-y-3'></div>
      <div className='relative w-full h-20 z-20 bg-white origin-left transform skew-y-6'></div>
    </div>
  )
}

export default Story
