import Logo from '../assets/Logo.svg'
    import SEO from '../assets/SEO.svg'
import Cone from '../assets/comingsoon/cone.png'
import Loop from '../assets/comingsoon/loop.webp'
import WaitingPic from '../assets/comingsoon/waitingPic.jpg'
import MediaBuying from '../assets/MediaBuying.svg'
import Printing from '../assets/Printing.svg'
import SocialMediaManagement from '../assets/SocialMediaManagement.svg'
import GraphicDesign  from '../assets/GraphicDesign.svg'
import WebDevelopment from '../assets/WebDevelopment.svg'
import Branding from '../assets/Branding.svg'
import BusinessDevelopment from '../assets/BusinessDevelopment.svg'
import Videography from '../assets/Videography.svg'
import PR from '../assets/PR.svg'
import Hero from '../assets/Hero.jpg'
import World from '../assets/World.png'
import ArtBoard1 from '../assets/Artboard 1.jpg'
import ArtBoard2 from '../assets/Artboard 2.jpg'
import ArtBoard3 from '../assets/Artboard 3.jpg'
import CroppedHero from '../assets/CroppedHero.jpg';
import ByXe1 from '../assets/ByXe1.jpg';
import ByXe2 from '../assets/ByXe2.jpg';

const images = {
    Logo,
    SEO,
    Cone,
    Loop,
    WaitingPic,
    MediaBuying,
    Printing,
    SocialMediaManagement,
    GraphicDesign ,
    WebDevelopment,
    Branding,
    BusinessDevelopment,
    Videography,
    PR,
    Hero,
    World,
    ArtBoard1,
    ArtBoard2,
    ArtBoard3,
    CroppedHero,
    ByXe1,
    ByXe2,
} 

export default images