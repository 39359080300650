import React from 'react'
import images from '../../../constants/Images'

const Hero = () => {
    return (
        <div className='mt-0 relative overflow-hidden h-full'>
            <div className='w-full hidden md:block h-full bg-gradient-to-b from-transparent to-neutral-900 opacity-80 z-10 absolute' />
            <div className='z-20 pt-10 md:pt-0 flex items-center justify-center absolute top-0 left-0  h-full w-full text-center'>
                <span className='font-bungee text-[42px] md:text-8xl lg:text-[140px] text-white'>Discover What's Beyond</span>
            </div>
            <img src={images.Hero} alt='' className='z-0 hidden md:block relative' />
            <img src={images.CroppedHero} alt='' className='z-0 md:hidden relative' />
        </div>
    )
}

export default Hero
