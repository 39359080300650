import React, { useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SubmissionError from "./SubmissionError";

const StudioBooking = ({ close, success, consultation = false }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [company, setCompany] = useState("");
  const [briefOfProject, setBriefOfProject] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    if (error) {
      const timeout = setTimeout(() => {
        setError(false);
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [error]);

  const resetStates = () => {
    setEmail("");
    setMessage("");
    setName("");
    setNumber("");
    setSelectedDate("");
  };

  const formSubmit = () => {
    if (name === "" || email === "" || number === "" || selectedDate === "") {
      setError(true);
      return;
    }

    const mailBody = `
      <p>Name: ${name}</p>
      <p>Email: ${email}</p>
      <p>Number: ${number}</p>
      <p>Date: ${selectedDate}</p>
      <p>Message: ${consultation ? "consultation" : message}</p>
      ${consultation && (
        `<p>Company: ${company}</p>`
      )}
      ${consultation && (
        `<p>Brief of project: ${briefOfProject}</p>`
      )}
    `;
    const config = {
      SecureToken: "019dfdba-601a-4249-b999-a4f2582f079e",
      To: "info@xekai.co",
      From: "info@xekai.co",
      Subject: "Message from contact form",
      Body: mailBody,
    };

    if (window.Email) {
      window.Email.send(config);
    }
    //MailBody to be sent by API
    resetStates();
    close();
    success();
  };

  return (
    <div className="fixed flex items-center justify-center font-oswald inset-0 z-50 bg-black bg-opacity-50 text-neutral-800 w-screen">
      {error && <SubmissionError />}
      <div className="flex flex-col items-center bg-gray-200 rounded-xl gap-y-4 p-4 m-8 md:m-0">
        <div className="flex justify-end w-full">
          <button
            onClick={close}
            className="text-neutral-700 text-2xl hover:text-neutral-600 rounded-full"
          >
            <MdCancel />
          </button>
        </div>
        {consultation ? <div className="flex flex-col gap-3 text-center max-w-sm"><span className="text-3xl font-semibold">Free Consultation</span><span className="text-[19px] w-full text-neutral-600">Transform your brand's vision into unforgettable experiences, book your free consultation with us today</span></div> : <p className="text-3xl font-semibold">Details of booking</p>}
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col items-start">
            <span className="text-xl mx-3 text-neutral-700">Name</span>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="rounded-lg w-full mx-auto md:w-fit md:mx-2 focus:outline-none py-2 px-2"
              type="text"
            ></input>
          </div>
          <div className={`flex flex-col items-start `}>
            <span className="text-xl mx-3 text-neutral-700">Date</span>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="dd/MM/yyyy"
              className="rounded-lg w-full mx-auto md:w-fit md:mx-2 focus:outline-none py-2 px-2"
            />
          </div>
          <div className="flex flex-col items-start">
            <span className="text-xl mx-3 text-neutral-700">Email</span>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="rounded-lg w-full mx-auto md:w-fit md:mx-2 focus:outline-none py-2 px-2"
              type="text"
            ></input>
          </div>
          <div className="flex flex-col items-start">
            <span className="text-xl mx-3 text-neutral-700">Mobile Number</span>
            <input
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              className="rounded-lg w-full mx-auto md:w-fit md:mx-2 focus:outline-none py-2 px-2"
              type="text"
            ></input>
          </div>
          {consultation && (
            <div className="flex flex-col items-start">
              <span className="text-xl mx-3 text-neutral-700">Company</span>
              <input
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                className="rounded-lg w-full mx-auto md:w-fit md:mx-2 focus:outline-none py-2 px-2"
                type="text"
              ></input>
            </div>
          )}
          {consultation && (
            <div className="flex flex-col items-start">
              <span className="text-xl mx-3 text-neutral-700">Brief of project</span>
              <input
                value={briefOfProject}
                onChange={(e) => setBriefOfProject(e.target.value)}
                className="rounded-lg w-full mx-auto md:w-fit md:mx-2 focus:outline-none py-2 px-2"
                type="text"
              ></input>
            </div>
          )}
        </div>

        {!consultation && (
          <div className={`flex w-full md:px-2 flex-col items-start`}>
            <span className="text-xl mx-2 md:mx-1 text-neutral-700">
              Message <span className="text-neutral-600">{`(optional)`}</span>
            </span>
            <input
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="rounded-lg w-full focus:outline-none py-2 px-0 md:px-2"
              type="text"
            />
          </div>
        )}

        <button
          onClick={formSubmit}
          className="m-3 p-2 px-8 w-fit text-2xl transition-all bg-yellow rounded-full duration-200 text-neutral-600 hover:text-neutral-800"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default StudioBooking;
