import Vision from "../assets/lotties/vision.json";
import Mission from "../assets/lotties/Mission.json";
import Story from "../assets/lotties/story.json";
import RightArrow from "../assets/lotties/yellow-right-arrow.json";
import Studio from "../assets/lotties/studio.json";
import Service from "../assets/lotties/service.json";
import SEO from "../assets/lotties/SEO.json";
import MediaBuying from "../assets/lotties/MediaBuying.json";
import Printing from "../assets/lotties/Printing.json";
import SocialMediaManagement from "../assets/lotties/SocialMediaManagement.json";
import GraphicDesign from "../assets/lotties/GraphicDesign.json";
import WebDevelopment from "../assets/lotties/WebDevelopment.json";
import Branding from "../assets/lotties/Branding.json";
import BusinessDevelopment from "../assets/lotties/BusinessDevelopment.json";
import Videography from "../assets/lotties/Videography.json";
import PR from "../assets/lotties/PR.json";
import Blogs from "../assets/lotties/Blogs.json";
const icons = {
  Mission,
  Vision,
  Story,
  RightArrow,
  Studio,
  Service,
  SEO,
  MediaBuying,
  Printing,
  SocialMediaManagement,
  GraphicDesign,
  WebDevelopment,
  Branding,
  BusinessDevelopment,
  Videography,
  PR,
  Blogs,
};

export default icons;
