import React from 'react'
import './StayTuned.css';
import images from '../../../constants/Images'
const ByXe = () => {
  return (
    <div id='byxe' className='relative w-full pt-36 bg-gradient-to-r from-yellow via-gray-100 to-cyan text-white '>
      <div className='flex items-center justify-center w-full font-oswald'>
        <h3 className=' text-neutral-800 text-[40px] underline underline-offset-8 text-center pb-8'>By Xe</h3>
      </div>
      <div className='w-full md:grid grid-cols-2 p-12 gap-8'>
        <div className='p-4 flex flex-col bg-white rounded-3xl'>
          <div>
            <img src={images.ByXe1} className='rounded-3xl' alt={'byxe2'}/>
          </div>
          <div className='text-md md:text-2xl text-neutral-800 p-2 md:p-6 font-oswald'>
            A real estate clubhouse that works inside Egypt and the United Arab Emirates. Which aims to make the real estate market better for clients & developers by using technology.
          </div>
        </div>
        <div className='mt-6 md:mt-0 p-4 flex flex-col bg-white rounded-3xl'>
          <div>
            <img src={images.ByXe2} className='rounded-3xl' alt={'byxe1'} />
          </div>
          <div className='text-md md:text-2xl text-neutral-800 p-2 md:p-6 font-oswald'>
            A startup e-commerce company set to launch at the beginning of 2025, whose main target is to lead the trading industry by blending traditional practices with modern innovations.
          </div>
        </div>
      </div>
      {/* <div className="flex items-center md:py-24 justify-center ">
        <div className="text-center p-6 rounded-xl bg-opacity-50 bg-black shadow-md transform transition duration-500 hover:scale-105">
          <h1 className="text-4xl md:text-6xl font-bold mb-4 animate-pulse">Stay Tuned</h1>
          <p className="text-lg md:text-xl">Exciting content is on the way!</p>
        </div>
      </div> */}
      <div className='absolute bottom-0 w-full h-20 z-30 bg-gradient-to-r from-yellow via-gray-100 to-cyan origin-left transform skew-y-3'></div>
      <div className='relative w-full h-20 z-20 bg-white origin-left transform skew-y-6'></div>
    </div>
  )
}

export default ByXe
