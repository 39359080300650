import React, { useState } from 'react'
import icons from '../../../constants/animatedIcons'
import images from '../../../constants/Images'
import Lottie from 'react-lottie'
import StudioBooking from '../../../components/StudioBooking'
import SuccessMessage from '../../../components/SuccessMessage'
import './StayTuned.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Requires a loader
import { Carousel } from 'react-responsive-carousel';

const XekaiStudio = () => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: icons.RightArrow,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    },
  };

  const [isBooking, setIsBooking] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const success = () => {
    setIsSuccess(true);
    setTimeout(() => {
      setIsSuccess(false);
    }, 5000);
  }
  const close = () => {
    setIsBooking(false);
  }

  return (
    <div id='studio' className='relative pt-20 md:pt-0 bg-gray-200'>
      <div className='w-full flex items-center justify-center'>
        {isSuccess && (<SuccessMessage />)}
      </div>
      <div className='flex items-center justify-center'>
        <h3 className='text-[40px] font-oswald lg:pt-32 text-neutral-800 lg:pb-0 pb-8 text-center underline underline-offset-8'>Xekai Studio</h3>
      </div>
      <div className='bg-gray-200 flex flex-col lg:grid grid-cols-12 items-center font-oswald px-12 gap-y-4 lg:pt-20 text-neutral-800 text-center'>

        {window.innerWidth < 1024 ?
          <div className='col-span-6 col-end-13 pt-4'>
            {/* <img src={images.Studio} alt='' className='rounded-3xl' /> */}
            <Carousel
              showArrows={true}
              showThumbs={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={2000}
              transitionTime={500}
              showStatus={true}
            >
              <div>
                <img src={images.ArtBoard2} alt="1" className="object-cover w-full" />
              </div>
              <div>
                <img src={images.ArtBoard3} alt="2" className="object-cover w-full" />
              </div>
              <div>
                <img src={images.ArtBoard1} alt="3" className="object-cover w-full" />
              </div>
            </Carousel>
          </div>
          : <></>
        }

        <div className='col-span-4 flex flex-col items-center'>
          <div className='text-xl md:text-2xl lg:text-4xl'>
            If you are a content creator or a company who wants to film their own content, we are here for you!
            We will offer you different setups with all the equipment you need to make your content different from others.
          </div>
          <div className='pt-10'>
            <button onClick={() => { setIsBooking(true) }} className='transition-all duration-500 hover:scale-105 hover:animate-pulse rounded-full text-3xl bg-yellow px-8 py-2'>Book Now</button>
            {isBooking && (<StudioBooking close={close} success={success} consultation={false} />)}
          </div>
        </div>
        <div className='col-span-2 z-30 hidden md:block ml-12 lg:ml-0'>
          <Lottie
            isClickToPauseDisabled={true}
            options={defaultOptions}
            height={200}
            width={350}
          />
        </div>
        <div className='hidden lg:block col-span-6 col-end-13 pt-4'>
          {/* <img src={images.Studio} alt='' className='rounded-3xl' /> */}
          <Carousel
            showArrows={true}
            showThumbs={false}
            infiniteLoop={true}
            autoPlay={true}
            interval={2000}
            transitionTime={500}
            showStatus={true}
          >
            <div>
              <img src={images.ArtBoard2} alt="1" className="object-cover w-full" />
            </div>
            <div>
              <img src={images.ArtBoard3} alt="2" className="object-cover w-full" />
            </div>
            <div>
              <img src={images.ArtBoard1} alt="3" className="object-cover w-full" />
            </div>
          </Carousel>
        </div>
      </div>
      <div className='absolute bottom-0 w-full h-20 z-30 bg-gray-200 origin-left transform skew-y-3'></div>
      <div className='relative w-full h-20 z-20 bg-white origin-left transform skew-y-6'></div>
    </div>
  )
}

export default XekaiStudio
