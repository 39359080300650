import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { servicesData } from "../../Data/ServiceData.js";
import { Header } from "../../components/Header.jsx";
import { Footer } from "../../components/Footer.jsx";
import ServiceCard from "../../components/ServicesCard.jsx";
import Lottie from "react-lottie";
import { Helmet } from "react-helmet-async";

const ServicePage = () => {
  const { serviceName } = useParams();
  const topRef = useRef(null);

  const [Logoheight, setLogoheight] = useState(300);
  const [Logowidth, setLogowidth] = useState(300);

  const selectedService = servicesData.find(
    (service) => service.serviceName === serviceName
  );

  const unselectedServices = servicesData.filter(
    (service) => service.serviceName !== serviceName
  );
  useEffect(() => {
    if (
      serviceName === "PR" ||
      serviceName === "GraphicDesignVideoEditing" ||
      serviceName === "MediaBuying"
    ) {
      setLogowidth(300);
      setLogoheight(300);
    } else {
      setLogowidth(300);
      setLogoheight(300);
    }
  }, [serviceName]);
  const defaultOptions = {
    loop: true,
    autoplay: false,
    animationData: selectedService.Logo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const renderServiceCards = () => {
    return unselectedServices.map((service, index) => (
      <div key={index} className="p-4 ">
        <div
          onClick={() => scrollToTop(service.serviceName)}
          className=""
        >
          {service.serviceName === "PR" ||
            service.serviceName === "GraphicDesignVideoEditing" ||
            service.serviceName === "MediaBuying" ? (
            <ServiceCard
              className="hover:bg-gray-300 bg-white border-gray-300 border-2 h-[310px] hover:shadow-yellow text-lg md:text-2xl"
              logoClassName={"h-[200px]"}
              Title={service.Title}
              Logo={service.Logo}
              serviceName={service.serviceName}
              Logoheight={180}
              Logowidth={240}
            />
          ) : (
            <ServiceCard
              className="hover:bg-gray-300 bg-white border-gray-300 border-2 h-[310px] hover:shadow-yellow text-lg md:text-2xl"
              logoClassName={"h-[200px]"}
              Title={service.Title}
              Logo={service.Logo}
              serviceName={service.serviceName}
              Logoheight={180}
              Logowidth={180}
            />
          )}
        </div>
      </div>
    ));
  };

  const scrollToTop = (ServiceName) => {
    if (topRef.current) {
      if (
        ServiceName === "PR" ||
        ServiceName === "GraphicDesignVideoEditing" ||
        ServiceName === "MediaBuying"
      ) {
        setLogowidth(390);
        setLogoheight(300);
      } else {
        setLogowidth(300);
        setLogoheight(300);
      }
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (!serviceName) {
    return (
      <div className="bg-white flex flex-col items-center">
        <h1>Service not found!</h1>
      </div>
    );
  }
  return (
    <div className="bg-white flex flex-col items-center font-roboto">
      <Helmet>
        <title>Service Page</title>
        <meta name='description' content='Discover our services' />
        <link rel='canonical' href='/' />
        <meta name='robots' content='noindex' />
      </Helmet>
      <div className="bg-black w-full mb-8">
        <Header isAbsolute={false} />
      </div>

      <div ref={topRef} />
      <div
        className="container bg-gray-300 flex flex-col mb-8 items-center rounded-3xl p-8 shadow-lg"
        style={{ width: "calc(88%)" }}
      >
        <h1 className="text-3xl font-semibold mb-4">{selectedService.Title}</h1>

        <Lottie
          isClickToPauseDisabled={false}
          options={defaultOptions}
          height={Logoheight}
          width={Logowidth}
          isStopped={true}
          isPaused={true}
        />

        <p className="text-center text-xl">{selectedService.text}</p>
      </div>

      <div className="flex flex-col md:grid md:grid-cols-3 font-semibold mx-16 p-2 ">{renderServiceCards()}</div>

      <Footer />
    </div>
  );
};

export default ServicePage;
